// CRM 相关接口
import request from '@/request/request'
import { domainName } from '@/config'


// 查询用户信息列表 (客户管理)
export const QueryAdminUserInfosApi = (params) => request.post('/admin/app/user/queryAdminUserInfos', params);
// 启用用户
export const UserEnableApi = (params) => request.get('/admin/app/user/enable', { params });
// 禁用用户
export const UserDisabledApi = (params) => request.get('/admin/app/user/disabled', { params });


// 学习数据
export const QueryLearnDataResultApi = (params) => request.post('/admin/course/learn/queryLearnDataResult', params);
// 导出-课程学习数据
export const ExportLearnDataResultApi = domainName + '/admin/course/learn/exportLearnDataResult';
// 导出-课节学习数据
export const ExportLearnDataDescResultApi = domainName + '/admin/course/learn/exportLearnDataDescResult';
// 数据详情
export const QueryLearnDataDescResultApi = (params) => request.post('/admin/course/learn/queryLearnDataDescResult', params);

// 修改密码
export const AetPasswordApi = (params) => request.post('/appv2/login/setPassword', params);
// 转移权益
export const MoveUserRights = (params) => request.post('/adminv2/crm/moveUserRights', params);
// 企微标签
export const getQwTags = (params) => request.post('/adminv2/crm/qwTags/list', params);
// 企微用户列表
export const getQwUsers = (params) => request.post('/adminv2/crm/qwUsers/list', params);
